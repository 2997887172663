import axios from "axios";
import { getAuth, getLoginId, getMemberId } from "../../auth/api/loginAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { appIntl } from "../../../i18n/IntlGlobalProvider";

export const projectid = "mbna88";
const domain = "mbna88";

let isAlertShowing = false; // Flag to track whether an alert is currently being displayed

const checkUserDataExist = () => {
  if (!getAuth()) {
    redirectBackToLogin();
  }
};

const redirectBackToLogin = () => {
  window.location.href = "/login";
};

// Create a cancel token source
const { CancelToken } = axios;
const cancelSource = CancelToken.source();

export const authPost = async (endPoint, data, signal) => {
  checkUserDataExist();

  // Pass the cancel token to axios request
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: endPoint,
      data: {
        ...data,
        memberid: getMemberId(),
        loginid: getLoginId(),
        projectid,
      },
      cancelToken: signal ? signal.token : cancelSource.token, // Pass cancel token
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log("-99:sessionend");
        } else {
          showAlertAndRedirect();
        }
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authFormPost = async (endPoint, data, signal) => {
  checkUserDataExist();

  // Pass the cancel token to axios request
  return axios
    .post(endPoint, data, {
      cancelToken: signal ? signal.token : cancelSource.token, // Pass cancel token
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log("-99:sessionend");
        } else {
          showAlertAndRedirect();
        }
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authGet = async (endPoint, data, signal) => {
  checkUserDataExist();
  const params = Object.entries({
    ...data,
    memberid: getMemberId(),
    loginid: getLoginId(),
    projectid,
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  // Pass the cancel token to axios request
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/getApi.php`, {
      url: `${endPoint}?${params}`,
      cancelToken: signal ? signal.token : cancelSource.token, // Pass cancel token
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log("-99:sessionend");
        } else {
          showAlertAndRedirect();
        }
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const doPost = async (endPoint, data, signal) => {
  // Pass the cancel token to axios request
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: endPoint,
      data: data,
      cancelToken: signal ? signal.token : cancelSource.token, // Pass cancel token
    })
    .catch((error) => handleError(error));
};

export const handleError = (error) => {
  if (!(error.message && error.message === "canceled")) {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("-99:sessionend");
    } else {
      showAlert(appIntl().formatMessage({ id: "errorOccurContactAdmin" }));
    }
  }
};

const showAlertAndRedirect = () => {
  window.dispatchEvent(new Event("sessionLost"));
};

const showAlert = (message) => {
  if (!isAlertShowing) {
    isAlertShowing = true; // Set flag to true
    alert(message);
    isAlertShowing = false; // Reset flag to false after alert is closed
  }
};

// Function to cancel requests
export const cancelRequests = () => {
  cancelSource.cancel("Request cancelled by the user");
};
