import { useIntl } from "react-intl";
import { Modal } from "../../../../common/components/Modal";
import { EditPasswordForm } from "./EditPasswordForm";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";

export const EditPasswordDialog = ({ setSwalProps }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(
      actions.updatePassword({ action: "changepassword", ...queryParams })
    ).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "changePasswordSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="passwordModal"
      title={intl.formatMessage({ id: "editPassword" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <EditPasswordForm formikRef={formikRef} saveValues={saveValues} />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {settingState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          style={{ color: "black", backgroundColor: "#f5be09" }}
          type="button"
          disabled={settingState.actionsLoading}
          className="btn btn-prim fw-bold px-3"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
