import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApiStatusTypeEnum } from "../../../../../../enumeration/ApiStatusTypeEnum";
import {
  CheckIsNull,
  CopyToClipboard,
} from "../../../../../../utils/CommonHelper";
import { useAuth } from "../../../../../auth/components/AuthInit";
import { projectid } from "../../../../../common/redux/ApiClient";
import * as actions from "../../../../redux/SettingAction";
import { BankAccountDialog } from "../bankAccountDialog/BankAccountListDialog";
import { DepositAtmForm } from "./DepositAtmForm";

export const DepositAtmDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const imageName = Date.now();
  const formikRef = useRef();
  const receiptRef = useRef();
  const { auth } = useAuth();
  const intl = useIntl();

  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedBank, setSelectedBank] = useState(0);
  const [isShow, setIsShow] = useState(false);

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getAtmDepositUI({ action: "topupmanualui" }));
    dispatch(actions.getDepositWalletUI({ action: "topupwalletui" })).then(
      (resp) => {
        resp = resp.data;
        setDepositToLastGame(!CheckIsNull(resp.companycode));
      }
    );
  }, []);

  const saveValues = (queryParams) => {
    const newQueryParams = {
      action: "topupmanualrequest",
      memberid: auth.memberid,
      loginid: auth.loginid,
      bankitemid: settingState.depositAtmUI.topupManualUIs[selectedBank].itemid,
      projectid,
      topupamount: queryParams.topupamount,
      imagename: `${imageName}.${queryParams.receipt.name
        .split(".")
        .slice(-1)}`,
      companycode: !CheckIsNull(companyCode)
        ? companyCode
        : depositToLastGame &&
          !CheckIsNull(settingState.depositWalletUI.companycode)
        ? settingState.depositWalletUI.companycode
        : "",
    };

    const formData = new FormData();
    formData.append("memberid", imageName);
    formData.append("receiptPath", queryParams.receipt);
    formData.append("jsonData", JSON.stringify(newQueryParams));

    dispatch(actions.submitAtmDepositRequest(formData)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "depositRequestSendSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            if (dismissBtnRef) {
              dismissBtnRef.current.click();
            }
            if (receiptRef.current) {
              receiptRef.current.value = null;
            }
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      {console.log(settingState)}
      {settingState.depositAtmUI &&
      settingState.depositWalletUI &&
      settingState.depositAtmUI.topupManualUIs.length > 0 ? (
        <>
          <BankAccountDialog
            selectedBank={selectedBank}
            setSelectedBank={setSelectedBank}
            bankList={settingState.depositAtmUI.topupManualUIs}
            isShow={isShow}
            setIsShow={setIsShow}
          />
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "depositAccount" })}
          </div>
          <div
            className="rounded custom-border p-3 d-flex justify-content-between align-items-center cursor-pointer"
            style={{ background: "rgba(0,0,0,0.2)" }}
            onClick={() => setIsShow(true)}
          >
            <div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "bankName" })}
              </div>
              <div className="mb-2  fw-bold text-font2">
                {
                  settingState.depositAtmUI.topupManualUIs[selectedBank]
                    .bankname
                }
              </div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "accountName" })}
              </div>
              <div className="mb-2 d-flex fw-bold align-items-center">
                <div className="me-3 text-font2">
                  {
                    settingState.depositAtmUI.topupManualUIs[selectedBank]
                      .accountname
                  }
                </div>
                <i
                  className="fa fa-copy cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sessionStorage.getItem("platform") === "mobile") {
                      console.log(
                        `clipboard: ${settingState.depositAtmUI.topupManualUIs[selectedBank].accountname}`
                      );
                      CopyToClipboard(
                        settingState.depositAtmUI.topupManualUIs[selectedBank]
                          .accountname,
                        setSwalProps,
                        intl
                      );
                    } else {
                      CopyToClipboard(
                        settingState.depositAtmUI.topupManualUIs[selectedBank]
                          .accountname,
                        setSwalProps,
                        intl
                      );
                    }
                  }}
                />
              </div>
              <div className="text-muted small text-font2">
                {intl.formatMessage({ id: "accountNumber" })}
              </div>
              <div className="fw-bold d-flex align-items-center">
                <div className="me-3 text-font2">
                  {
                    settingState.depositAtmUI.topupManualUIs[selectedBank]
                      .accountnum
                  }
                </div>
                <i
                  className="fa fa-copy cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sessionStorage.getItem("platform") === "mobile") {
                      console.log(
                        `clipboard: ${settingState.depositAtmUI.topupManualUIs[selectedBank].accountnum}`
                      );
                      CopyToClipboard(
                        settingState.depositAtmUI.topupManualUIs[selectedBank]
                          .accountnum,
                        setSwalProps,
                        intl
                      );
                    } else {
                      CopyToClipboard(
                        settingState.depositAtmUI.topupManualUIs[selectedBank]
                          .accountnum,
                        setSwalProps,
                        intl
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <i className="fa fa-angle-right fs-1 me-4" />
            </div>
          </div>
          <div className="my-4">
            <DepositAtmForm
              formikRef={formikRef}
              receiptRef={receiptRef}
              saveValues={saveValues}
            />
          </div>
          {settingState.depositWalletUI &&
            CheckIsNull(companyCode) &&
            !CheckIsNull(settingState.depositWalletUI.content) && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="depositWalletUI"
                  type="checkbox"
                  checked={depositToLastGame}
                  onChange={(e) => {
                    setDepositToLastGame(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label text-font2"
                  htmlFor="depositWalletUI"
                  dangerouslySetInnerHTML={{
                    __html: settingState.depositWalletUI.content.replace(
                      /\*(.*?)\*/g,
                      "<span class='text-danger fw-bold text-font3'>$1</span>"
                    ),
                  }}
                />
              </div>
            )}
          <button
            type="button"
            disabled={settingState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center text-font3">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </>
      ) : (
        <Skeleton className="w-100" height="500px" />
      )}
    </>
  );
};
